"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RequestData = /** @class */ (function () {
    function RequestData(array, pagination, search) {
        this._array = array || [];
        this._pagination = pagination || {};
        this._search = search || "";
    }
    RequestData.prototype.setArray = function (array) {
        return new RequestData(array, this.pagination, this.search);
    };
    RequestData.prototype.setPagination = function (pagination) {
        return new RequestData(this.array, (typeof pagination !== "undefined") ? pagination : this.pagination, this.search);
    };
    RequestData.prototype.setSearch = function (search) {
        return new RequestData(this.array, this.pagination, search);
    };
    RequestData.prototype.updateArray = function (updatedElement, key) {
        if (key === void 0) { key = "id"; }
        var array = key
            ? this.array.map(function (element) { return element[key] === updatedElement[key] ? updatedElement : element; })
            : this.array.map(function (element) { return element === updatedElement ? updatedElement : element; });
        return new RequestData(array, this.pagination, this.search);
    };
    Object.defineProperty(RequestData.prototype, "array", {
        get: function () {
            return this._array;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RequestData.prototype, "pagination", {
        get: function () {
            return this._pagination;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RequestData.prototype, "search", {
        get: function () {
            return this._search;
        },
        enumerable: true,
        configurable: true
    });
    return RequestData;
}());
exports.default = RequestData;
