"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __ = window.$lang;
exports.default = {
    "permissions": {
        "districts": __("Hufce"),
        "access districts": __("Zakładka hufców"),
        "view districts": __("Dane hufców"),
        "create district": __("Tworzenie hufców"),
        "edit district": __("Edytowanie hufców"),
        "delete district": __("Usuwanie hufców"),
        "edit last modified date": __("Edytowanie daty ostatniej modyfikacji składek"),
        "administrators": __("Administratorzy"),
        "view district administrators": __("Dane administratorów hufców"),
        "create district administrator": __("Tworzenie administratorów hufców"),
        "edit district administrator": __("Edytowanie administratorów hufców"),
        "delete district administrator": __("Usuwanie administratorów hufców"),
        "units": __("Jednostki"),
        "access units": __("Zakładka jednostek"),
        "view units": __("Dane jednostek"),
        "create unit": __("Tworzenie jednostek"),
        "edit unit": __("Edytowanie jednostek"),
        "delete unit": __("Usuwanie jednostek"),
        "view unit administrators": __("Dane administratorów drużyn"),
        "create unit administrator": __("Tworzenie administratorów drużyn"),
        "edit unit administrator": __("Edytowanie administratorów drużyn"),
        "delete unit administrator": __("Usuwanie administratorów drużyn"),
        "last_modified": __("Data ostatniej modyfikacji"),
        "autocomplete units": __("Automatyczne przyznawanie uprawnień do utworzonych jednostek"),
        "scouts": __("Harcerze"),
        "access scouts": __("Zakładka harcerzy"),
        "view scouts": __("Dane harcerzy"),
        "view archived scouts": __("Przeglądanie archiwalnych harcerzy"),
        "create scout": __("Dodawanie harcerzy"),
        "edit scout": __("Edytowanie danych harcerzy"),
        "registrationNumber": __("Numer ewidencyjny"),
        "view registration number": __("Przeglądanie numerów ewidencyjnych"),
        "edit registration number": __("Edytowanie numerów ewidencyjnych"),
        "update scout contributions": __("Aktualizacja składek harcerzy"),
        "archive scout": __("Archiwizowanie harcerzy"),
        "settings": __("Ustawienia"),
        "access settings": __("Zakładka ustawień"),
        "contributions": __("Składki"),
        "contributions settings": __("Ustawienia składek"),
        "amounts settings": __("Ustawienia wysokości składek"),
        "transfer details settings": __("Edycja szczegółów przelewu"),
        "users": __("Użytkownicy"),
        "access users": __("Zakładka użytkowników"),
        "view user": __("Dane użytkowników"),
        "create user": __("Dodawanie użytkowników"),
        "edit user": __("Edytowanie danych użytkowników"),
        "delete user": __("Usuwanie użytkowników"),
        "roles": __("Role użytkowników"),
        "access roles": __("Zakładka ról"),
        "view roles": __("Dane ról użytkowników"),
        "create role": __("Tworzenie ról użytkowników"),
        "edit role": __("Edytowanie ról użytkowników"),
        "delete role": __("Usuwanie ról użytkowników"),
        "other": __("Inne"),
        "hide from others": __("Ukryj użytkownika z tą rolą")
    }
};
