"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TableHeader = /** @class */ (function () {
    function TableHeader(title, align, visible) {
        if (align === void 0) { align = "left"; }
        if (visible === void 0) { visible = true; }
        this.title = title;
        this.align = align;
        this.visible = visible;
    }
    return TableHeader;
}());
exports.default = TableHeader;
