"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __ = window.$lang;
exports.default = {
    common: {
        unrecognizedError: __("Wystąpił nieokreślony błąd."),
        invalidEmailAddress: __("Nieprawidłowy adres e-mail."),
        invalidPassword: __("Hasło nie może być puste.")
    },
    district: {
        all: __("Nie udało się pobrać hufców."),
        create: __("Nie udało się dodać hufca."),
        rename: __("Nie udało się zmienić nazwy hufca."),
        delete: __("Nie udało się usunąć hufca."),
        invalidName: __("Nazwa hufca nie może być pusta."),
        invalidLastModified: __("Nieprawidłowa data ostatniej modyfikacji."),
        transferDetails: __("Nie udało się pobrać szczegółów przelewu."),
        updateTransferDetails: __("Nie udało się zaktualizować szczegółów przelewu.")
    },
    role: {
        all: __("Nie udało się pobrać ról."),
        permissions: __("Nie udało sie pobrać uprawnień dla roli."),
        create: __("Nie udało się stworzyć nowej roli."),
        delete: __("Nie udało się usunąć roli."),
        deleted: __("Rola została usunięta."),
        rename: __("Nie udało się zmienić nazwy roli."),
        invalidName: __("Nazwa roli użytkownika nie może być pusta."),
        permission: {
            update: __("Nie udało się zmienić uprawnienia roli.")
        }
    },
    unit: {
        all: __("Nie udało się pobrać jednostek."),
        create: __("Nie udało się dodać jednostki."),
        delete: __("Nie udało się usunąć jednostki."),
        rename: __("Nie udało się zmienić nazwy jednostki."),
        district: {
            update: __("Nie udało się zmienić hufca, do którego przynależy jednostka.")
        },
        invalidDistrict: __("Należy wybrać hufiec.")
    },
    user: {
        all: __("Nie udało się pobrać użytkowników."),
        create: __("Nie udało się stworzyć nowego użytkownika."),
        update: __("Nie udało się zaktualizować danych użytkownika."),
        changePassword: __("Nie udało się zmienić hasła."),
        delete: __("Nie udało się usunąć użytkownika."),
        invalidName: __("Nazwa użytkownika nie może być pusta."),
        invalidRoles: __("Należy wybrać przynajmniej jedną rolę."),
        invalidUnits: __("Wybrana jednostka nie należy do wybranego hufca.")
    },
    scout: {
        all: __("Nie udało się pobrać harcerzy."),
        create: __("Nie udało się dodać nowego harcerza."),
        update: __("Nie udało się zaktualizować danych harcerza."),
        archive: __("Nie udało archiwizować harcerza."),
        invalidFirstName: __("Imię harcerza nie może być puste."),
        invalidLastName: __("Nazwisko harcerza nie może być puste."),
        invalidRegistrationNumber: __("Numer ewidencyjny jest nieprawidłowy."),
        invalidJoinedAt: __("Nieprawidłowa data dołączenia do ZHP."),
        invalidQuitAt: __("Nieprawidłowa data wystąpienia z ZHP."),
        invalidCurrentDistrict: __("Należy wybrać hufiec."),
        invalidCurrentUnit: __("Należy wybrać jednostkę.")
    },
    contribution: {
        update: __("Nie udało się zaktualizować stanu składki."),
        status: __("Nie udało się pobrać stanu składek harcerza.")
    },
    setting: {
        update: __("Nie udało się zaktualizować ustawień.")
    },
    amounts: {
        all: __("Nie udało się pobrać wysokości składek."),
        update: __("Nie udało się zaktualizować wysokości składek.")
    }
};
